import React from 'react';
import { useTranslation } from 'react-i18next';


const Location = () => {
    const { t } = useTranslation();

    const styles = {
        wrapper: {

        }
    };

    return (
        <div style={styles.wrapper}>
            <div className="container pt10">
                <div className="row mv40">
                    <div className="col mb20">
                        <h5 className="fw400 text-center fs30" style={{ textTransform: 'upperCase' }}>{t('aboutUs:location')}</h5>
                    </div>
                </div>
                <div className="row mv40">
                    <div className="col-sm-12 col-lg-5 mb20">
                        
                        <p><b>Biuwer Analytics S.L.</b><br/>
                            CEEIM. Campus Universitario Espinardo.<br/>
                            30100 Murcia (Spain)<br/>
                            VAT ESB10513877<br/>
                            +34 868 941 353
                        </p>
                    </div>
                    <div className="col-sm-12 col-lg-7 mb20"> 
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.43894547882!2d-1.1761856235305772!3d38.01354519836906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd63813002433235%3A0x38f2bbb70d422a36!2sBIUWER!5e0!3m2!1ses!2ses!4v1690284679313!5m2!1ses!2ses" 
                            width="100%" 
                            height="400" 
                            style={{ border: 0 }} 
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade" 
                            title="Biuwer Headquarters"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location;