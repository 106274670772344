import React from 'react';
import { useTranslation } from 'react-i18next';

import TeamMember from './team-member';

// Team members images
import teamImage1 from  '../../../static/img/team/alberto-morales.jpeg';
import teamImage2 from  '../../../static/img/team/jose-miguel-morales.jpeg';
import teamImage3 from  '../../../static/img/team/ruben-jimenez.jpeg';
import teamImage4 from  '../../../static/img/team/manuel-contreras.jpeg';
import teamImage5 from  '../../../static/img/team/eloy-torres.jpeg';

const Team = () => {
    const { t } = useTranslation();

    const members = [
        {
            name: "Alberto Morales",
            position: "Cofounder & CEO",
            image: teamImage1
        },
        {
            name: "José Miguel Morales",
            position: "Cofounder & CTO",
            image: teamImage2
        },
        {
            name: "Rubén Jimenez",
            position: "Data Engineer",
            image: teamImage3
        },
        {
            name: "Manuel Contreras",
            position: "Full Stack Developer",
            image: teamImage4
        },
        {
            name: "Eloy Torres",
            position: "Full Stack Developer",
            image: teamImage5
        },
    ];

    const styles = {
        wrapper: {
            maxWidth: 900,
            margin: '0 auto'
        }
    };

    return (
        <div style={styles.wrapper}>
            <div className="container pt10">
                <div className="row mv40">
                    <div className="col mb20">
                        <h5 className="fw400 text-center fs30" style={{ textTransform: 'upperCase' }}>{t('aboutUs:team')}</h5>
                    </div>
                </div>
                <div className="row mv40">
                    {members.map( (member, index) => 
                        <TeamMember
                            key={index}
                            name={member.name}
                            position={member.position}
                            memberImage={member.image} 
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Team;