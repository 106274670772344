import React from 'react';
import { navigate } from 'gatsby';
import { Button  } from "@blueprintjs/core";

class RequestDemoSection extends React.Component  {
    render() {
        const { t, i18n } = this.props;

        return (
            <div className="container pv40">
                <div className="row mb30">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <h2 style={{ fontWeight: 'bold' }}>{t('requestDemoSection.msg1')}</h2>
                        <h5>{t('requestDemoSection.msg2')}</h5>
                    </div>
                </div>
                <div className="row mb30">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <div>
                            <Button
                                text={t("requestDemoSection.msg3").toUpperCase()}
                                intent="primary"
                                style={{ boxShadow: 'none', marginTop: 15, marginRight: 20 }}
                                large={true}
                                onClick={() => i18n.language === 'en' ? navigate('/en/book-demo/') : navigate('/es/reserva-demo/')}
                            />
                            <a
                                href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success"
                                style={{ marginTop: 15, color: 'white', textDecoration: 'none', height: 40 }}
                            >
                                {t("mainMenuLinks.tryForFree").toUpperCase()}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestDemoSection;