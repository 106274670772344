import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import MainLayout from '../../components/main-layout';
import Vision from './vision';
import Mission from './mission';
import Team from './team';
import Location from './location';
import RequestDemoSection from '../../components/RequestDemoSection';

const AboutUs = (props) => {
    const { pageContext } = props;
    const { t, i18n } = useTranslation();

    return (
        <MainLayout pageContext={pageContext}>
            <Vision />
            <Mission />
            <Team />
            <Location />
            <RequestDemoSection key="request-demo-section" {...props} t={t} i18n={i18n}/>
        </MainLayout>
    )
}

export default AboutUs;