import React from 'react';
import { useTranslation } from 'react-i18next';

const Vision = () => {
    const { t } = useTranslation();

    return (
        <div className="container pt40" style={{ marginTop: 72 }}>
            <div className="row mv40">
                <div className="col-sm-12 mb20">
                    <h1 className="mb20 text-center">{t('aboutUs:visionMessage')}</h1>
                    <h5 className="mb40 text-center fw400 fs25" style={{ textTransform: 'upperCase' }}>{t('aboutUs:vision')}</h5>
                </div>
            </div>
        </div>
    )
}

export default Vision;