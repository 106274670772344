import React from 'react';
import { grey } from 'material-colors/dist/colors';

const TeamMember = (props) => {
    const { name, position, memberImage } = props;

    const styles = {
        wrapper: {
            marginBottom: '20px'
        },
        imageWrapper: {
            with: '100%'
        },
        image: {
            maxWidth: '100%'
        },
        infoWrapper: {
            backgroundColor: grey[50],
            padding: '20px 10px'
        },
        name: {
            fontWeight: 'bold'
        },
        position: {
            fontSize: '13px'
        }
    };

    return (
        <div className='col-sm-12 col-md-4' style={styles.wrapper}>
            <div style={styles.imageWrapper}>
                <img style={styles.image}
                    src={memberImage}
                    alt={name}
                />
            </div>
            <div style={styles.infoWrapper}>
                <h6 style={styles.name}>{name}</h6>
                <p style={styles.position}>{position}</p>
            </div>
        </div>
    )
}

export default TeamMember;