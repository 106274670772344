import React from 'react';
import { useTranslation } from 'react-i18next';

import biuwerTeamImage from '../../../static/img/biuwer-team.jpg';

const Mission = () => {
    const { t } = useTranslation();

    return (
        <div className="container" style={{ marginTop: 72 }}>
            <div className="row mv40">
                <div className="col-sm-12 col-lg-6 mb20">
                    <h5 className="mb40 fw400 fs30" style={{ textTransform: 'upperCase' }}>{t('aboutUs:mission')}</h5>
                    <h3 className="mb20">{t('aboutUs:missionMessage1')}</h3>
                    <h5 className="mb20 fw400">{t('aboutUs:missionMessage2')}</h5>
                    <h5 className="mb20 fw400">{t('aboutUs:missionMessage3')}</h5>
               </div>
                <div className="col-sm-12 col-lg-6 mb20" style={{ textAlign: 'right' }}>
                    <img style={{ maxWidth: '100%', width: 600 }}
                            src={biuwerTeamImage}
                            alt={"Biuwer Team"}
                        />
                </div>
            </div>
        </div>
    )
}

export default Mission;